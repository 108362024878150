<template>
  <v-navigation-drawer
    right
    class="navigation-drawer"
    :value="dialog"
    :height="this.best_height"
    :width="this.best_widht"
    app
  >
    <template v-slot:prepend>
      <v-app-bar color="#064c72" dark>
        <v-app-bar-title>Orden de Compra</v-app-bar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="close()">
          <v-icon>mdi-close </v-icon>
        </v-btn>
      </v-app-bar>
    </template>
    <v-container>
      <v-card elevation="0">
        <v-card-text class="text-uppercase">
          {{ this.$store.getters.profile.name }}<br />
          {{ this.$store.getters.profile.email }}
        </v-card-text>
      </v-card>
    </v-container>
    <v-data-table
      calculate-widths
      :headers="header"
      :items="cart"
      :items-per-page="-1"
      item-key="index"
      hide-default-footer
      sort-by="name"
      mobile-breakpoint="0"
      class="table-cursor"
      loading-text="Cargando ..."
    >
      <template v-slot:top></template>
      <template v-slot:[`item.product_name`]="{ item }" class="pa-0 ma-0">
        <div @click="editItm(item)">
          <b class="text-uppercase">{{ item.product_name }}</b>
          <br />
          Pack : {{ item.pack }} X {{ item.size + " " + item.uom }}

          <br />
          <span v-if="item.shipping_time > 1">
            Tiempo de entrega: {{ item.shipping_time }} días hábiles.
          </span>
          <span v-else> Tiempo de entrega: {{ item.shipping_time }} día hábil. </span>
          <span v-if="item.note" v-html="item.note" />
        </div>
      </template>
      <template v-slot:[`item.price`]="{ item }" class="text-right">
        <span class="text-decoration-line-through red--text" v-if="item.discount"
          >{{
            "$" +
            (
              parseFloat(item.price) *
              parseFloat(item.qty) *
              parseFloat(item.pack)
            ).toLocaleString()
          }}<br
        /></span>
        <b>{{ "$" + parseFloat(item.tItem).toLocaleString() }}</b>
        <v-spacer></v-spacer>
        <v-btn icon x-small @click="deleteItem(item)">
          <v-icon>mdi-trash-can-outline</v-icon>
        </v-btn>
      </template>
      <template slot="body.append">
        <tr>
          <th class="text-center totals">
            {{ totals.qty.toLocaleString(2) }}
          </th>
          <th class="text-right totals">Sub-total</th>
          <th class="text-right totals">
            {{ this.totals.subtotal.toLocaleString() }}
          </th>
        </tr>
        <tr>
          <th></th>
          <th class="text-right totals">Impuesto</th>
          <th class="text-right totals">
            {{ this.totals.tax.toLocaleString() }}
          </th>
        </tr>
        <tr>
          <th></th>
          <th class="text-right totals">Totales</th>
          <th class="text-right totals">
            {{ this.totals.total.toLocaleString() }}
          </th>
        </tr>
      </template>
    </v-data-table>

    <template v-slot:append>
      <v-form
        v-model="valid"
        ref="issueForm"
        @submit="send_order"
        v-if="$store.getters.profile.code"
      >
        <v-row class="pa-2">
          <v-col cols="12">
            <v-select
              v-model="branch"
              hide-details="auto"
              :rules="f_required"
              item-value="value"
              :items="branches"
              label="Dirección de Entrega"
              return-object
              outlined
            >
              <template slot="selection" slot-scope="data">
                <!-- HTML that describe how select should render selected items -->
                {{ data.item.name }} - {{ data.item.address }}
              </template>
              <template slot="item" slot-scope="data">
                <!-- HTML that describe how select should render items when the select is open -->
                {{ data.item.name }} - {{ data.item.address }}
              </template>
            </v-select>
          </v-col>
          <!-- <v-col cols="6">
            <v-text-field
              v-model="delivery_issue.issueDate"
              type="date"
              hide-details="auto"
              label="Fecha de entrega"
              :rules="f_valid_dates"
              outlined
              required
            />
          </v-col>
          <v-col cols="6">
            <v-combobox
              v-model="delivery_issue.issueTime"
              hide-details="auto"
              label="Horario de entrega"
              :items="delivery_times"
              :rules="f_required"
              outlined
              required
            />
          </v-col> -->
        </v-row>
        <v-toolbar dark color="#064c72" dense>
          <v-spacer></v-spacer>
          <v-btn text rounded dark type="submit" :disabled="loading_status">
            Enviar Orden
          </v-btn>
          <v-spacer></v-spacer>
        </v-toolbar>
      </v-form>
      <v-toolbar dark color="#064c72" dense v-else>
        <v-spacer></v-spacer>
        <v-btn text rounded dark to="Registration"> Registrarse </v-btn>
        <v-spacer></v-spacer>
      </v-toolbar>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { webserver, getIssueDate } from "../services/webserver.js";
export default {
  name: "Shoppingcart",
  components: {},
  props: {
    dialog: {
      type: Boolean,
      required: true,
      default: false,
    },
    cart: {
      type: Array,
      required: true,
      default: null,
    },
  },
  data() {
    return {
      loading_status: false,
      header: [
        {
          text: "Cant.",
          align: "center",
          value: "qty",
          width: 2,
        },
        {
          text: "Descripción",
          align: "start",
          sortable: true,
          value: "product_name",
          dataType: "text",
        },
        {
          text: "Valor",
          align: "end",
          sortable: true,
          value: "price",
        },
      ],
      header_selected: [
        {
          text: "",
          align: "start",
          value: "product_name",
        },
        {
          text: "",
          align: "end",
          value: "price",
        },
      ],
      headerSelected: [
        {
          text: "",
          align: "start",
          value: "product_name",
        },
        {
          text: "",
          align: "end",
          value: "price",
          width: 20,
        },
      ],
      sc: { qty: 0, subtotal: 0, discount: 0, tax: 0, total: 0 },
      f_required: [(v) => !!v || "Requerido"],
      f_valid_dates: [
        (v) => !!v || "El código es requerido",
        (v) => v >= getIssueDate(-1) || "Fecha no valida",
        (v) =>
          v <= getIssueDate(-15) || "Máximo 15 días de antelación : " + getIssueDate(-15),
      ],
      delivery_issue: { issueDate: getIssueDate(-1), issueTime: null },
      delivery_times: [
        "6:00 a.m. - 10:00 a.m.",
        "10:00 a.m. - 02:00 p.m.",
        "02:00 a.m. - 06:00 p.m.",
      ],
      valid: false,
      best_height: "100%",
      best_widht: "500",
      branches: [],
      branch: null,
      totals: { qty: 0, total: 0, subtotal: 0, tax: 0, paid: 0, unpaid: 0 },
    };
  },
  methods: {
    close() {
      this.$emit("close", true);
    },
    onResize() {
      this.best_height = window.innerHeight;
      if (window.innerWidth < 480) {
        this.best_widht = window.innerWidth;
      }
    },
    editItm(e) {
      var index = this.cart.indexOf(e);
      console.log("editItm", index, e);
      e.options_selected = JSON.stringify(e.options_selected);
      this.$emit("edit", e, index);
    },
    send_order(e) {
      e.preventDefault();
      this.valid = this.$refs.issueForm.validate();
      if (this.valid) {
        var perfil = { ...this.$store.getters.profile };

        perfil.name = this.branch.name;
        perfil.address = this.branch.address;
        perfil.city = this.branch.city;

        this.updateTotals();
        this.cart.forEach((itm) => {
          delete itm.percent;
        });

        var order = {
          pos: this.$store.getters.company,
          party: perfil,
          total: this.sc.total,
          items: this.cart,
        };

        order.total = this.sc.total;

        order.qty = parseFloat(this.totals.qty);
        order.total = parseFloat(this.totals.total);
        order.tax = parseFloat(this.totals.tax);
        order.subtotal = parseFloat(this.totals.subtotal);

        order.account = this.$store.getters.company.account;
        order.doc_type = "OR";
        order.store = this.$store.getters.company.code;
        order.userId = this.$store.getters.profile.code;
        order.agent = "WEB";
        order.status = "PENDIENTE";
        order.delivery_date = this.delivery_issue.issueDate;
        order.delivery_time = this.delivery_issue.issueTime;
        order.items = this.cart;

        this.loading_status = true;
        webserver("put_doc", order, (data) => {
          this.loading_status = false;
          if (data.header) {
            this.cart = [];
            localStorage.cart = Buffer.from(JSON.stringify(this.cart)).toString("base64");
            this.$emit("resetCart", true);
          }
          this.$router.push("Checkout/?did=" + data.header.doc_code);
        });
      }
    },
    deleteItem(e) {
      var index = this.cart.indexOf(e);
      if (index !== -1) {
        this.cart.splice(index, 1);
      }
      localStorage.cart = Buffer.from(JSON.stringify(this.cart)).toString("base64");
      this.$emit("update", true);
      this.updateTotals();
    },
    // updateTotals() {
    //   var itms = this.cart;
    //   this.sc = { qty: 0, subtotal: 0, discount: 0, tax: 0, total: 0 };
    //   itms.forEach((itm) => {
    //     itm.qty = parseFloat(itm.qty);
    //     itm.pack = parseFloat(itm.pack);
    //     itm.cost = parseFloat(itm.cost);
    //     itm.price = parseFloat(itm.price);
    //     itm.discount = parseFloat(itm.discount);
    //     var tax_value = parseFloat(itm.tax) / 100 + 1;

    //     itm.discount_value = (
    //       (parseFloat(itm.price) * parseFloat(itm.discount)) /
    //       100
    //     ).toFixed(2);

    //     itm.price_value = (
    //       parseFloat(itm.price) - parseFloat(itm.discount_value)
    //     ).toFixed(2);

    //     itm.price_amount = Math.round(parseFloat(itm.price_value) / tax_value).toFixed(2);

    //     itm.tax_value = Math.round(
    //       (parseFloat(itm.price_amount) *
    //         parseFloat(itm.qty) *
    //         parseFloat(itm.pack) *
    //         itm.tax) /
    //         100
    //     ).toFixed(2);

    //     itm.tItem = (
    //       parseFloat(itm.price_amount) * parseFloat(itm.qty) * parseFloat(itm.pack) +
    //       parseFloat(itm.tax_value)
    //     ).toFixed(2);

    //     this.sc.subtotal += parseFloat(itm.tItem) - parseFloat(itm.tax_value);
    //     this.sc.tax += parseFloat(itm.tax_value) * parseFloat(itm.qty);
    //     this.sc.total += parseFloat(itm.tItem) * parseFloat(itm.qty);
    //   });
    // },
    updateTotals() {
      this.totals.qty = 0;
      this.totals.subtotal = 0;
      this.totals.total = 0;
      this.totals.tax = 0;
      this.totals.paid = 0;
      this.totals.unpaid = 0;
      this.totals.costs = 0;
      // this.order.total = 0;
      // this.order.tax = 0;
      // this.order.qty = 0;
      var itms = this.cart;

      itms.forEach((itm) => {
        itm.qty = parseFloat(itm.qty);
        itm.pack = parseFloat(itm.pack);
        itm.cost = parseFloat(itm.cost);
        itm.price = parseFloat(itm.price);
        itm.discount = parseFloat(itm.discount);
        // var tax_value = parseFloat(itm.tax) / 100 + 1;

        itm.discount_value = (
          (parseFloat(itm.price) * parseFloat(itm.discount)) /
          100
        ).toFixed(2);

        // itm.price_value = (
        //   parseFloat(itm.price) - parseFloat(itm.discount_value)
        // ).toFixed(2);

        // itm.price_amount = Math.round(parseFloat(itm.price_value) / tax_value).toFixed(2);

        // itm.tax_value = Math.round(
        //   (parseFloat(itm.price_amount) *
        //     parseFloat(itm.qty) *
        //     parseFloat(itm.pack) *
        //     itm.tax) /
        //     100
        // ).toFixed(2);

        // itm.tItem = (
        //   parseFloat(itm.price_amount) * parseFloat(itm.qty) * parseFloat(itm.pack) +
        //   parseFloat(itm.tax_value)
        // ).toFixed(2);

        var tax_value = parseFloat(itm.tax_value) * parseFloat(itm.qty);

        itm.tItem =
          (parseFloat(itm.price_amount) + parseFloat(itm.tax_value)) *
          parseFloat(itm.qty);

        // itm.tax_base = parseFloat(itm.tItem) - parseFloat(itm.tax_value);

        this.totals.qty += parseFloat(itm.qty);
        this.totals.subtotal += parseFloat(itm.price_amount) * parseFloat(itm.qty);
        this.totals.total += parseFloat(itm.tItem);
        this.totals.tax += tax_value;
        console.log("XXXX", this.totals);
      });
      // this.doc.payments.forEach((itm) => {
      //   this.totals.paid += parseFloat(itm.payment_value);
      // });
      this.totals.unpaid = this.totals.total - this.totals.paid;
      // this.payment.payment_value = this.totals.unpaid;

      // if (this.totals.unpaid === 0 && this.totals.total > 0) {
      //   this.payment_dialogo = false;
      //   this.topay = true;
      //   //this.setSku();
      //   //this.save_invoice();
      // } else {
      //   this.topay = false;
      // }
    },
    selecttype(e) {
      return e[0].unique;
    },
    sumField(fld, key) {
      return this[fld].reduce((a, b) => parseFloat(a) + (parseFloat(b[key]) || 0), 0);
    },
  },
  mounted() {
    window.addEventListener("resize", this.onResize, { passive: true });
    var perfil = window.localStorage.getItem("profile");
    if (perfil) {
      var itm = JSON.parse(
        this.CryptoJS.AES.decrypt(perfil, process.env.VUE_APP_QR4).toString(
          this.CryptoJS.enc.Utf8
        )
      );
      this.item = itm;
      if (itm.mobile) {
        this.$store.dispatch("setProfile", itm);
      }
      this.branches.push({
        name: itm.name,
        address: itm.address,
        city: itm.city,
      });
    }

    if (this.$store.getters.profile.shipping_address) {
      this.branches.push.apply(
        this.branches,
        JSON.parse(this.$store.getters.profile.shipping_address)
      );
    }
    this.updateTotals();
  },
  watch: {
    cart: function (itm) {
      console.log("cart", itm);
      this.updateTotals();
    },
  },
};
</script>

<style></style>
