<template>
  <div :height="this.best_height" :width="this.best_widht">
    <v-btn
      fab
      dark
      fixed
      bottom
      left
      color="success"
      :href="apilink"
      target="_blank"
      class="wtsppbtn"
    >
      <v-icon size="30" color="white">mdi-whatsapp</v-icon>
    </v-btn>

    <v-btn
      fab
      dark
      fixed
      top
      right
      color="red"
      class="ml-3 cartbtn"
      elevation="0"
      @click="cart_dialog = !cart_dialog"
      :disabled="!sc.qty > 0"
      v-if="!isMobile"
    >
      <v-icon>mdi-cart-outline</v-icon>
      {{ sc.qty }}
    </v-btn>

    <v-toolbar fixed class="navbar" :color="company_color" dark dense>
      <v-tabs center-active>
        <v-tab
          v-for="catego in smart_categories"
          :key="catego"
          color="info"
          @click="changeData(catego)"
        >
          {{ catego }}
        </v-tab>
      </v-tabs>

      <!-- <template v-if="!isMobile">
        <v-text-field
          light
          placeholder="Buscar"
          v-model="tosearch"
          append-icon="mdi-magnify"
          clearable
          outlined
          hide-details
          filled
          background-color="white"
          dense
          @keyup="findProduct(this)"
        />
        <v-spacer></v-spacer>
        <v-btn
          color="transparent"
          class="ml-3"
          elevation="0"
          @click="cart_dialog = !cart_dialog"
          :disabled="!sc.qty > 0"
        >
          <v-icon>mdi-cart-outline</v-icon>
          {{ sc.qty }}
        </v-btn>
      </template> -->
    </v-toolbar>

    <!-- <section class="parnertship">
      <v-slide-group
        mobile-breakpoint="0"
        center-active
        active-class="success"
        show-arrows
      >
        <v-slide-item v-for="(catego, i) in smart_categories" :key="i">
          <v-card elevation="0" color="transparent">
            <v-avatar
              color="white"
              size="117"
              class="pa-3 mr-3"
              @click="changeData(catego)"
            >
              <v-img contain height="100" :src="getbgpic(catego)"></v-img>
            </v-avatar>

            <v-card-actions class="justify-center pa-0">
              {{ catego }}
            </v-card-actions>
          </v-card>
        </v-slide-item>
      </v-slide-group>
    </section> -->

    <v-bottom-navigation :background-color="company_color" dark app v-if="isMobile">
      <v-btn icon dark @click="catetory = ''">
        <v-icon>mdi-format-list-checkbox</v-icon>Lista
      </v-btn>
      <!-- <v-menu offset-y auto>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon dark v-bind="attrs" v-on="on">
            <v-icon>mdi-format-list-checkbox</v-icon>Lista
          </v-btn>
        </template>
        
        <v-list>
          <v-list-item v-for="(item, index) in smart_categories" :key="index">
            <v-list-item-title @click="changeData(item)">{{ item }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu> -->
      <v-text-field
        light
        placeholder="Buscar"
        rounded
        class="pt-2"
        full-width
        v-model="tosearch"
        append-icon="mdi-magnify"
        clearable
        outlined
        hide-details
        filled
        background-color="white"
        dense
        @keyup="findProduct(this)"
      />
      <v-btn
        color="transparent"
        elevation="0"
        @click="cart_dialog = !cart_dialog"
        :disabled="!sc.qty > 0"
      >
        <v-icon>mdi-cart-outline</v-icon>
        {{ sc.qty }}
      </v-btn>
    </v-bottom-navigation>

    <v-container v-if="catetory === ''">
      <v-layout align-center justify-center>
        <v-flex xs12 sm12 md12 lg12>
          <v-row class="toptop">
            <v-col v-for="catego in smart_categories" :key="catego" cols="auto">
              <v-card
                color="transparent"
                elevation="10"
                rounded
                @click="changeData(catego)"
                v-if="getbgpic(catego)"
                class="text-center card-outter"
                width="160px"
              >
                <v-avatar color="white" rounded size="150">
                  <v-img contain :src="getbgpic(catego)">
                    <!-- <div primary-title class="titleCentered tshadow">{{ catego }}</div> -->
                  </v-img>
                </v-avatar>

                <v-card-title class="justify-center card-actions tshadow">
                  <span width="100%">{{ catego }}</span>
                </v-card-title>
              </v-card>
              <v-card
                dark
                height="160px"
                width="160px"
                elevation="10"
                @click="changeData(catego)"
                v-else
              >
                <div class="titleCentered tshadow">{{ catego }}</div>
              </v-card>
            </v-col>
          </v-row>
        </v-flex>
      </v-layout>
    </v-container>

    <!-- <v-expansion-panels tile width="100%" fill-height class="toptop" v-if="isMobile">
      <v-expansion-panel v-for="(item, i) in menu" :key="i">
        <v-expansion-panel-header>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                <h3>{{ i }}</h3>
              </v-list-item-title>
            </v-list-item-content>

          </v-list-item>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-data-table
            :headers="headers[$vuetify.breakpoint.xsOnly]"
            :items="menu[i]"
            :items-per-page="-1"
            item-key="index"
            hide-default-footer
            sort-by="name"
            mobile-breakpoint="0"
            class="table-cursor pa-0"
            loading-text="Cargando ..."
            @click:row="addItem"
          >
            <template v-slot:top></template>
            <template v-slot:[`item.picture`]="{ item }">
              <v-avatar>
                <v-img
                  contain
                  :src="getImagePhoto(item.account, item.store, item.picture)"
                  v-if="item.picture"
                />
              </v-avatar>
            </template>

            <template v-slot:[`item.product_name`]="{ item }">
              <b class="text-uppercase">{{ item.product_name }}</b
              ><br />
              <small>{{ item.note }}</small>
            </template>
            <template v-slot:[`item.price`]="{ item }" class="text-right">
              {{
                (
                  item.qty *
                  (parseFloat(item.price) + parseFloat(item.value_options))
                ).toLocaleString()
              }}
            </template>
          </v-data-table>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels> -->
    <template v-else>
      <!-- <v-container class="toptop">
        <v-layout align-center justify-center>
          <v-flex>
            <v-row>
              <v-col
                cols="12"
                md="6"
                sm="6"
                lg="4"
                v-for="(child, index) in smart_lst_selected"
                :key="index"
              >
                <v-card
                  elevation="1"
                  tile
                  height="100%"
                  @click="addItem(child)"
                  class="card-outter"
                >
                  <v-img
                    max-height="250px"
                    width="100%"
                    contain
                    :src="getImagePhoto(child.account, child.store, child.picture)"
                    v-if="child.picture"
                  ></v-img>
                  <v-btn
                    v-if="child.discount"
                    color="success"
                    elevation="10"
                    rounded
                    dark
                    small
                    absolute
                    top
                    right
                    >{{ "-" + child.percent + "%" }}</v-btn
                  >
                  <v-card-title class="text-wrap">{{ child.product_name }}</v-card-title>
                  <v-card-text
                    v-if="child.description"
                    class="overflow-hidden"
                    v-html="child.description.substring(0, 200)"
                  >
                  </v-card-text>
                  <v-spacer></v-spacer>
                  <v-btn
                    v-if="child.discount"
                    color="red"
                    elevation="0"
                    text
                    dark
                    small
                    absolute
                    bottom
                    right
                    class="btnprice"
                  >
                    <span class="text-decoration-line-through">{{
                      "$" + parseFloat(child.price).toLocaleString()
                    }}</span>
                  </v-btn>

                  <v-card-actions v-if="child.pack > 0" class="card-actions">
                    <span>{{
                      parseFloat(child.price_uom).toFixed(2) +
                      " x " +
                      child.normalized_uom
                    }}</span>
                    <v-spacer></v-spacer>
                    <span class="font-weight-bold text-lowercase">
                      {{
                        "$" +
                        Math.round(
                          parseFloat(child.price) - parseFloat(child.discount)
                        ).toLocaleString() +
                        " x " +
                        child.uom
                      }}
                    </span>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-flex>
        </v-layout>
      </v-container> -->
      <v-container fluid fill-height class="toptop">
        <v-layout align-center justify-center>
          <v-flex xs12 sm12 md12 lg10>
            <!-- <v-list width="100%" class="toptop" three-line elevation="0"> -->
            <v-row justify="center">
              <v-col
                cols="6"
                md="auto"
                sm="auto"
                lg="auto"
                v-for="(child, index) in smart_lst_selected"
                :key="index"
                @click="addItem(child)"
              >
                <!-- <v-card height="100%" elevation-1>
                    <v-list-item>
                      <v-btn
                        v-if="child.discount"
                        color="success"
                        elevation="10"
                        rounded
                        dark
                        small
                        absolute
                        top
                        rigth
                        class="topleft"
                        >{{ "-" + child.discount + "%" }}</v-btn
                      >
                      <v-list-item-avatar
                        rounded
                        size="100"
                        class="pa-2"
                        v-if="child.picture"
                      >
                        <v-img
                          contain
                          :src="getImagePhoto(child.account, child.store, child.picture)"
                        ></v-img>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-card flat>

                          <v-card-title class="text-wrap">{{
                            child.product_name
                          }}</v-card-title>

                          <v-card-text
                            v-html="child.description.substring(0, 200)"
                            v-if="child.description"
                          ></v-card-text>
                          <v-card-text class="pa-0" v-else>
                            {{ child.category + " / " + child.subcategory }}
                          </v-card-text>

                          <v-card-actions class="pa-0">
                            Pack : {{ child.size + " " + child.uom }} X {{ child.pack }}
                            <v-spacer></v-spacer>
                            <span v-if="$store.getters.profile.code">
                              {{
                                "$" +
                                Math.round(
                                  parseFloat(child.tItem) -
                                    parseFloat(child.discount_value)
                                ).toLocaleString(0)
                              }}
                            </span>
                          </v-card-actions>
                          <v-btn
                            v-if="child.discount"
                            color="red"
                            elevation="0"
                            text
                            dark
                            small
                            absolute
                            bottom
                            right
                            class="btnprice"
                          >
                            <span class="text-decoration-line-through">{{
                              "$" + parseFloat(child.tItem).toLocaleString()
                            }}</span>
                          </v-btn>
                        </v-card>
                      </v-list-item-content>
                    </v-list-item>
                  </v-card> -->

                <v-card
                  elevation="0"
                  tile
                  height="100%"
                  min-height="230px"
                  @click="addItem(child)"
                  class="card-outter pa-2"
                  width="230px"
                  color="blue-grey lighten-5"
                >
                  <v-img
                    contain
                    :src="getImagePhoto(child.account, child.store, child.picture)"
                    v-if="child.picture"
                    height="250px"
                  >
                    <v-btn
                      v-if="child.discount"
                      color="success"
                      elevation="10"
                      rounded
                      dark
                      small
                      absolute
                      top
                      right
                      >{{ "-" + child.percent + "%" }}</v-btn
                    >
                  </v-img>

                  <v-card-title class="justify-center text-wrap">{{
                    child.product_name
                  }}</v-card-title>
                  <!-- <v-card-text
                    v-if="child.description"
                    v-html="child.description.substring(0, 200)"
                  >
                  </v-card-text> -->
                  <v-spacer></v-spacer>
                  <v-btn
                    v-if="child.discount"
                    color="red"
                    elevation="0"
                    text
                    dark
                    small
                    absolute
                    bottom
                    right
                    class="btnprice"
                  >
                    <span class="text-decoration-line-through">{{
                      "$" + parseFloat(child.price).toLocaleString()
                    }}</span>
                  </v-btn>

                  <v-card-actions v-if="child.pack > 0" class="card-actions">
                    {{
                      parseFloat(child.price_uom).toFixed(2) +
                      " x " +
                      child.normalized_uom
                    }}
                    <v-spacer></v-spacer>
                    <span class="font-weight-bold text-lowercase">
                      {{
                        "$" +
                        Math.round(
                          parseFloat(child.price) - parseFloat(child.discount)
                        ).toLocaleString()
                      }}
                    </span>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
            <!-- </v-list> -->
          </v-flex>
        </v-layout>
      </v-container>
    </template>

    <template>
      <!-- <v-list v-if="isMobile" width="100%" three-line elevation="0">
          <v-list-item
            v-for="(child, index) in smart_lst_selected"
            :key="index"
            @click="addItem(child)"
            class="product_item"
          >
            <v-btn
              v-if="child.discount"
              color="success"
              elevation="10"
              rounded
              dark
              small
              absolute
              top
              rigth
              class="topleft"
              >{{ "-" + child.discount + "%" }}</v-btn
            >
            <v-list-item-avatar size="80">
              <v-img
                contain
                :src="getImagePhoto(child.account, child.store, child.picture)"
                v-if="child.picture"
              ></v-img>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-card flat>
                <v-card-title class="pa-0 text-wrap">{{
                  child.product_name
                }}</v-card-title>

                <v-card-text
                  class="pa-0"
                  v-html="child.description.substring(0, 200)"
                  v-if="child.description"
                ></v-card-text>
                <v-card-text class="pa-0" v-else>
                  {{ child.category + " / " + child.subcategory }}
                </v-card-text>

                <v-card-actions class="pa-0">
                  Pack : {{ child.size + " " + child.uom }} X {{ child.pack }}
                  <v-spacer></v-spacer>
                  <span v-if="$store.getters.profile.code">
                    {{
                      "$" +
                      Math.round(
                        parseFloat(child.tItem) - parseFloat(child.discount_value)
                      ).toLocaleString(0)
                    }}
                  </span>
                </v-card-actions>
                <v-btn
                  v-if="child.discount"
                  color="red"
                  elevation="0"
                  text
                  dark
                  small
                  absolute
                  bottom
                  right
                  class="btnprice"
                >
                  <span class="text-decoration-line-through">{{
                    "$" + parseFloat(child.price).toLocaleString()
                  }}</span>
                </v-btn>
              </v-card>
            </v-list-item-content>
          </v-list-item>
        </v-list> -->
    </template>

    <v-snackbar
      v-model="snackToast.dialog"
      :timeout="snackToast.timeout_error"
      dark
      :color="snackToast.color"
      elevation="24"
      >{{ this.snackToast.text }}
    </v-snackbar>

    <CartAdd
      :dialog="add_dialog"
      :itemSelected="itemSelected"
      :idx="idx"
      :addItm="addItm"
      @close="
        add_dialog = false;
        updateTotals();
      "
    />

    <ShoppingCart
      :dialog="cart_dialog"
      :cart="cart"
      @update="updateTotals"
      @edit="editItem"
      @resetCart="resetCart"
      @close="cart_dialog = !cart_dialog"
    />
  </div>
</template>

<script>
import { webserver } from "../../services/webserver.js";
import CartAdd from "../../components/Cart_add.vue";
import ShoppingCart from "../../components/Cart.vue";
import createItem from "../../utils/createItem.js";
import createParty from "../../utils/createParty.js";

export default {
  components: { CartAdd, ShoppingCart },
  beforeMount() {
    // console.log("beforeMount", this.$store.getters.company);
    // this.$store.dispatch("setHost", window.location.host);
    //this.$store.dispatch("setHost", "sw67.ca");
  },
  mounted() {
    //this.get_company();

    if (this.$route.params.Cart) {
      this.cart_dialog = this.$route.params.Cart;
    }

    // if (!this.$store.getters.company.account) {
    //   this.$router.push("/");
    // }

    // var perfil = window.localStorage.getItem("profile");
    // if (perfil) {
    //   var itm = JSON.parse(
    //     this.CryptoJS.AES.decrypt(perfil, process.env.VUE_APP_QR4).toString(
    //       this.CryptoJS.enc.Utf8
    //     )
    //   );
    //   this.item = itm;
    //   if (itm.mobile) {
    //     this.$store.dispatch("setProfile", itm);
    //   }
    // }
    this.updateTotals();
    window.addEventListener("resize", this.onResize, { passive: true });
    this.onResize();
    this.$store.dispatch("setNavStatus", true);
    this.get_bestprice();
  },
  data: () => ({
    idx: null,
    company: createParty(),
    company_color: "#064c72",
    cart_dialog: false,
    isXs: false,
    categories: [],
    items: [
      {
        id: 3,
        name: "Mis pedidos",
        description: "Creear, editar o eliminar pedidos.",
        icon: "mdi-file-document-edit-outline",
        to: "Material",
        color: "warning",
      },
      {
        id: 1,
        name: "Lista de Precios",
        description: "Creear, editar o eliminar productos de la lista de precios.",
        icon: "mdi-store-outline",
        color: "warning",
        to: "Proveedores",
      },
      {
        id: 2,
        name: "Proveedores",
        description: "Creear, editar o eliminar proveedores.",
        icon: "mdi-file-document-edit-outline",
        to: "Material",
        color: "warning",
      },

      {
        id: 4,
        name: "Mi perfil",
        description: "Editar perfil, logo, información de contacto.",
        icon: "mdi-file-document-edit-outline",
        to: "Material",
        color: "warning",
      },
    ],
    loading_status: false,
    smart_categories: [],
    smart_lst_selected: [],
    smart_lst: [],
    menu: [
      {
        id: 1,
        name: "Hacer pedido",
        description: "Creear, editar o eliminar productos de la lista de precios.",
        icon: "mdi-store-outline",
        color: "#455A64",
        to: "/",
      },
      {
        id: 2,
        name: "Historico de Pedidos",
        description: "Creear, editar o eliminar clientes.",
        icon: "mdi-file-document-edit-outline",
        to: "MyOrders",
        color: "#455A64",
      },
    ],
    selectedItem: 0,
    tab: "orders",
    store_dialog: false,
    itemSelected: createItem(),
    add_dialog: false,
    addItm: false,
    Scart: false,
    sc: { qty: 0, subtotal: 0, discount: 0, tax: 0, total: 0 },
    clientDialog: false,
    orders: [],
    headers: {
      false: [
        {
          text: "",
          align: "start",
          sortable: true,
          value: "picture",
          dataType: "text",
          with: "1",
        },
        {
          text: "Producto",
          align: "start",
          sortable: true,
          value: "product_name",
          dataType: "text",
        },
        {
          text: "Size",
          align: "center",
          value: "size",
          width: 2,
        },
        {
          text: "",
          align: "center",
          value: "price_uom",
          width: 2,
        },
        {
          text: "Pack.",
          align: "center",
          value: "pack",
          width: 2,
        },
        {
          text: "Valor",
          align: "end",
          sortable: true,
          value: "price",
        },
      ],
      true: [
        {
          text: "",
          align: "start",
          sortable: true,
          value: "picture",
          dataType: "text",
          with: "1",
        },
        {
          text: "Producto",
          align: "start",
          sortable: true,
          value: "product_name",
          dataType: "text",
        },

        {
          text: "Valor",
          align: "end",
          sortable: true,
          value: "price",
        },
      ],
    },
    header_orders: [
      {
        text: "Fecha",
        align: "start",
        sortable: true,
        value: "issue_date",
        dataType: "date",
      },
      {
        text: "Orden",
        align: "start",
        value: "doc_code",
        dataType: "text",
      },
      {
        text: "Valor",
        align: "end",
        sortable: true,
        value: "total",
      },
    ],
    snackToast: {
      timeout_error: 500,
      dialog: false,
      text: "",
      color: "warning",
    },
    tosearch: "",
    catetory: "",
    reg_dialog: false,
    cart: [],
    apilink: "",
    best_height: "100%",
    best_widht: "500",
  }),
  created: function () {
    this.apilink = "http://";
    //this.apilink += this.isXs ? 'api' : 'web';
    this.apilink +=
      "api.whatsapp.com/send?phone=+573204749856&text=" +
      encodeURI("Deseo más información ...");
  },
  methods: {
    resetCart() {
      this.cart = [];
    },
    changeData(e) {
      this.catetory = e;
      this.smart_lst_selected = this.menu[e];
    },
    findProduct() {
      if (this.tosearch) {
        this.smart_lst_selected = this.smart_lst.filter((o) => {
          if (o.product_name.toLowerCase().includes(this.tosearch.toLowerCase())) {
            return true;
          }
        });
      } else {
        this.smart_lst_selected = this.smart_lst;
      }
    },
    onResize() {
      this.isXs = window.innerWidth < 500;
      this.best_height = window.innerHeight;
      if (window.innerWidth < 480) {
        this.best_widht = window.innerWidth;
      }
    },
    openLink(e) {
      this.$router.push("/" + e + "/");
    },
    sumField(fld, key) {
      return this[fld].reduce((a, b) => a + (b[key] || 0), 0);
    },

    get_bestprice() {
      if (!this.$store.getters.company.account) {
        this.$router.push("/");
      } else {
        var qry = {
          account: this.$store.getters.company.account,
          table: "raw_material",
          filters: [
            {
              field: "account",
              operator: "=",
              value: this.$store.getters.company.account,
            },
            { field: "active", operator: "=", value: "1" },
            { field: "price_amount", operator: ">", value: 0 },
          ],
        };
        this.loading_status = true;
        console.log(qry);
        webserver("get_table", qry, (data) => {
          console.log("get_table", data);
          data.forEach((rg) => {
            delete rg.codelp;
            delete rg.costlp;
            delete rg.cost_max;
            delete rg.cost_avg;
            delete rg.sup_qty;
            delete rg.utility;
            delete rg.bp_code;
            rg.qty = 1;
            rg.note = "";
            rg.qty = parseFloat(rg.qty);
            rg.pack = parseFloat(rg.pack);
            rg.cost = parseFloat(rg.cost);
            rg.price = parseFloat(rg.price);
            rg.discount = parseFloat(rg.discount);
            //var tax_value = parseFloat(rg.tax) / 100 + 1;

            rg.discount_value = (
              (parseFloat(rg.price) * parseFloat(rg.discount)) /
              100
            ).toFixed(2);

            // rg.price_value = (parseFloat(rg.price) - parseFloat(rg.discount_value)).toFixed(
            //   2
            // );

            // rg.price_amount = Math.round(parseFloat(rg.price_value) / tax_value).toFixed(2);

            // rg.tax_value = Math.round(
            //   (parseFloat(rg.price_amount) *
            //     parseFloat(rg.qty) *
            //     parseFloat(rg.pack) *
            //     rg.tax) /
            //     100
            // ).toFixed(2);

            rg.tItem =
              (parseFloat(rg.price_amount) + parseFloat(rg.tax_value)) *
              parseFloat(rg.qty);
          });

          var ctgry = data.reduce(function (r, a) {
            r[a.category] = r[a.category] || [];
            r[a.category].push(a);
            return r;
          }, Object.create(null));

          for (const [key] of Object.entries(ctgry)) {
            ctgry[key].sort(function (a, b) {
              var textA = a.product_name.toUpperCase();
              var textB = b.product_name.toUpperCase();
              return textA < textB ? -1 : textA > textB ? 1 : 0;
            });
          }
          this.menu = ctgry;
          this.smart_categories = Object.keys(ctgry).sort();
          this.smart_lst_selected = data;
          //this.smart_lst_selected = this.menu["Pescado"];

          this.loading_status = false;
          this.smart_lst = data;
          // this.changeMenu();
        });
      }
    },
    getbgpic(ctgry) {
      var phtslst = [];
      if (ctgry) {
        phtslst = this.menu[ctgry];
      } else {
        phtslst = this.menu;
      }
      if (phtslst.length > 0) {
        // phtslst = phtslst.filter(function (el) {
        //   return el.picture !== "";
        // });

        phtslst = phtslst.filter(function (item) {
          if (item["picture"] !== "" && item["main"] !== "1") {
            return false;
          } else {
            return true;
          }
        });
      }

      if (phtslst.length > 0) {
        var rdm = phtslst[(phtslst.length * Math.random()) | 0];

        if (rdm.picture) {
          return this.getImagePhoto(rdm.account, rdm.store, rdm.picture);
        } else {
          return "";
        }
      } else {
        return "";
      }
    },
    getImagePhoto: function (account, store, src) {
      var url = "https://" + this.$store.getters.getHost + "/" + account + "/" + src;
      // console.log(url);
      if (this.compressed_image) url = this.compressed_image;
      return url;
    },
    // editProduct(data) {
    //   this.item = { ...data };
    //   this.newItem = false;
    //   this.dialog = true;
    // },
    updateTotals() {
      this.sc.qty = 0;
      this.sc.subtotal = 0;
      this.sc.discount = 0;
      this.sc.tax = 0;
      this.sc.total = 0;
      if (localStorage.cart) {
        this.cart = JSON.parse(Buffer.from(localStorage.cart, "base64"));
        this.cart.forEach((itm) => {
          itm.qty = parseFloat(itm.qty);
          itm.pack = parseFloat(itm.pack);
          itm.cost = parseFloat(itm.cost);
          itm.price = parseFloat(itm.price);
          itm.discount = parseFloat(itm.discount);

          // var tax_value = parseFloat(itm.tax) / 100 + 1;
          itm.discount_value = (
            (parseFloat(itm.price) * parseFloat(itm.discount)) /
            100
          ).toFixed(2);

          itm.price_value = (
            parseFloat(itm.price) - parseFloat(itm.discount_value)
          ).toFixed(2);

          // itm.price_amount = Math.round(parseFloat(itm.price_value) / tax_value).toFixed(
          //   2
          // );

          // itm.tax_value = Math.round(
          //   (parseFloat(itm.price_amount) *
          //     parseFloat(itm.qty) *
          //     parseFloat(itm.pack) *
          //     itm.tax) /
          //     100
          // ).toFixed(2);

          itm.tItem =
            (parseFloat(itm.price_amount) + parseFloat(itm.tax_value)) *
            parseFloat(itm.qty);

          this.sc.qty += parseFloat(itm.qty);
          this.sc.total += parseFloat(itm.tItem);
        });
        // this.updateTotals();
      } else {
        this.cart = [];
      }
      // var itms = this.cart;
      // var qty = 0;
      // var tax = 0;
      // var total = 0;

      // this.sc.qty = qty;
      // this.sc.subtotal = 0;
      // this.sc.discount = 0;
      // this.sc.tax = tax;

      this.snackToast = {
        dialog: true,
        text: "Pedido actualizado.",
        color: "warning",
        timeout_error: 500,
      };
    },
    addItem(e) {
      //console.log(e);
      this.addItm = true;
      this.itemSelected = { ...e };
      this.Scart = false;
      this.add_dialog = true;
    },
    editItem(e, idx) {
      console.log(e, idx);
      this.addItm = false;
      this.options_selected = null;
      this.optSelected = [];
      if (e.options_selected) {
        e.options_selected = JSON.parse(e.options_selected);
      }
      // if (e.product_options) {
      //   var opt = JSON.parse(e.product_options);
      //   if (opt.data) {
      //     this.options_selected = opt.data.reduce(function (r, a) {
      //       r[a.category] = r[a.category] || [];
      //       r[a.category].push(a);
      //       return r;
      //     }, Object.create(null));
      //     var sel = Object.keys(this.options_selected);
      //     sel.forEach((rg) => {
      //       this.optionsLst[rg] = [];
      //     });
      //   }
      // }

      //this.itemSelected = e;
      this.itemSelected = { ...e };
      this.idx = idx;

      //this.Scart = false;
      this.add_dialog = true;
    },
    // save_order() {
    //   //console.log(this.$store.getters.profile);
    //   this.get_oders();
    //   this.tab = "orders";
    // },
    // get_store() {
    //   var qry = {
    //     table: "stores",
    //     filters: [{ field: "code", operator: "=", value: this.company.code }],
    //   };
    //   webserver("get_table", qry, (data) => {
    //     //console.log(data);
    //     this.$store.dispatch("setCompany", data[0]);
    //     this.get_bestprice();
    //   });
    // },
    // get_oders() {
    //   var qry = {
    //     table: "docs",
    //     filters: [
    //       { field: "doc_type", operator: "=", value: "OR" },
    //       { field: "store", operator: "=", value: "mayorista.app" },
    //       {
    //         field: "userId",
    //         operator: "=",
    //         value: this.$store.getters.profile.code,
    //       },
    //     ],
    //   };
    //   webserver("get_table_clients", qry, (data) => {
    //     //console.log(data);
    //     this.orders = data;
    //   });
    // },
  },
  watch: {},
  computed: {
    isMobile() {
      if (screen.width <= 760) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style lang="css" scope>
.bg-texture {
  background: url("../../assets/fish1.jpg") no-repeat center center fixed;

  /* background: black; */
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 100vh;
}

.product_title {
  font-size: 1rem !important;
  font-weight: bolder !important;
  word-break: normal !important;
  color: #455a64;
}

.product_item {
  padding: 0px;
  border-style: none;
  border-color: #cfd8dc;
  border-width: 0.1px;
  border-bottom-style: solid;
}

.topleft {
  top: 10px !important;
  right: 10px !important;
  z-index: 1;
}

.wtsppbtn {
  bottom: 70px !important;
}

.cartbtn {
  top: 100px !important;
}

.toptop {
  padding-top: 80px !important;
}

.btom {
  bottom: 0px !important;
}

.btnprice {
  bottom: 20px !important;
  right: 5px !important;
}

.price {
  font-size: 0.8rem;
}

.navbar {
  overflow: hidden;
  position: fixed;
  /* Set the navbar to fixed position */
  top: 56;
  /* Position the navbar at the top of the page */
  width: 100%;
  /* Full width */
  z-index: 2;
}

.v-slide-group__content {
  justify-content: center;
}

.parnertship {
  /* background: linear-gradient(to right, transparent, rgba(0, 0, 0, 0.578)), url("../assets/login.jpg") no-repeat center center; */
  background: linear-gradient(to right, transparent, rgba(200, 200, 200, 0.607));
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  width: 100%;
}

.v-expansion-panel-content__wrap {
  padding: 0px !important;
}

.v-expansion-panel--active:not(:first-child),
.v-expansion-panel--active + .v-expansion-panel {
  margin-top: 0px !important;
}

.tshadow {
  text-shadow: black -1px 2px 6px;
  color: black;
}

.titleCentered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.product_text {
  height: 120px !important;
  /* overflow-y: auto !important; */
}

.v-card__text,
.v-card__title {
  word-break: normal; /* maybe !important  */
}

.v-card__title {
  font-weight: normal;
  font-size: 10px;
  line-height: 1em;
}

.v-list-item__avatar {
  margin-right: 5px !important;
}

.card-outter {
  padding-bottom: 50px;
}

.card-actions {
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  font-size: 14px;
  background-color: white;
}
</style>
